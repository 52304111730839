import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/providers.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/styles/prosemirror.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/ui/auth/sign-out.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Avatar","AvatarImage","AvatarFallback"] */ "/vercel/path0/ui/ui/avatar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ModeToggle"] */ "/vercel/path0/ui/ui/mode-toggle.tsx");
